<script>
import { GET_ASSET_METADATA } from '../../../store/actions'
import IconLink from '../../../assets/icon-link.vue'

export default {
  components: { IconLink },
  layout: 'pos',
  async asyncData({ params, store }) {
    const { assetId } = params
    if (assetId) {
      // fetch data from API
      try {
        const res = await store.dispatch(`pos/${GET_ASSET_METADATA}`, {
          assetId,
        })

        //     returns the following values to data() {
        //   userData: res,
        //   pdf_url,
        //   name,
        //   frontend_props,
        //   frontTemplate,
        //   backTemplate,
        //   statusVerify,
        //   photoURL,
        // }
        return res
      }
      catch (error) {
        console.error(error)
        // Redirect to error page or 404 depending on server response
      }
      finally {
        store.commit('setIsLoading', false)
      }
    }
  },
  data() {
    return {
      metadataTable: [
        { key: 'Token ID', value: 'id' },
        { key: 'Envelope ID', value: 'envelopeId' },
        { key: 'Original file', value: 'documentUrl' },
        { key: 'Document hash', value: 'documentHash' },
        { key: 'Description', value: 'description' },
        { key: 'Signers', value: 'signers' },
        { key: 'NFT Metadata', value: 'plaintext' },
      ],
    }
  },
  methods: {
    parseSigner(signer) {
      if (signer?.lastIndexOf?.('.') > -1) {
        return signer
      }
      else { return this.$options.filters.truncate(signer, 7) }
    },

    getValue(val) {
      return this[val]
    },
  },
}
</script>

<template>
  <v-container class="pos">
    <v-row>
      <v-col cols="12" sm="6" class="pa-0 mb-10 mt-4 mt-sm-12">
        <h1>
          {{ name }}
        </h1>
        <h3 class="mt-3">
          {{ 'Metadata details' }}
        </h3>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-card class="nft-card pa-10">
          <v-container>
            <v-row>
              <v-col cols="12" md="5">
                <img
                  class="nft-image"
                  :src="image"
                  style="
                    width: 100%;
                    max-width: 440px;
                    max-height: 440px;
                    aspect-ratio: 1/1;
                  "
                >
              </v-col>

              <v-col class="pl-3 pl-sm-9" cols="12" md="7">
                <v-row
                  v-for="item in metadataTable"
                  :key="item.name"
                  justify="space-between"
                  class="metadata-row"
                >
                  <v-col v-if="item.value !== 'plaintext'" cols="auto">
                    <p class="bold">
                      {{ item.key }}
                    </p>
                  </v-col>

                  <v-col
                    v-if="item.value !== 'plaintext'"
                    :cols="item.value === 'signers' ? 12 : 9"
                    class="text-right"
                    :class="item.value === 'signers' ? 'pt-0' : ''"
                  >
                    <v-container
                      v-if="item.value === 'signers'"
                      class="d-flex justify pt-0"
                    >
                      <v-row>
                        <v-col
                          v-for="signer in getValue(item.value)"
                          :key="signer"
                          cols="auto"
                        >
                          <div class="signer-ballon font-normal white--text">
                            {{ parseSigner(signer) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <div v-else>
                      <a
                        v-if="item.value === 'documentUrl'"
                        :href="getValue(item.value)"
                        target="_blank"
                        class="font-description link"
                      >
                        {{ truncate(getValue(item.value), 32, 'end') }}
                        <IconLink />
                      </a>
                      <p v-else class="font-description">
                        {{ getValue(item.value) }}
                      </p>
                    </div>
                  </v-col>

                  <v-expansion-panels v-else>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <p class="bold">
                          {{ item.key }}
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p class="font-tooltip">
                          {{ getValue(item.value) }}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.pos {
  margin-bottom: 100px;
  .nft-card {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5) !important;
  }
  .nft-image {
    border-radius: 13px;
  }
  .metadata-row {
    .col {
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 0;
    }
    &:not(:last-child) {
      border-bottom: solid 1px #eeeeee;
    }
  }

  .signer-ballon {
    border-radius: 24px;
    background-color: var(--teal-blue);
    padding: 6px 24px;
    width: fit-content;
    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  .v-expansion-panel {
    &::before {
      content: none;
    }
  }

  .v-expansion-panel-header {
    width: unset;
    padding-left: 0px;
  }

  .v-expansion-panel-content__wrap {
    padding-left: 12px;
  }

  h1 {
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3c4148;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3c4148;
  }
  p {
    margin-bottom: 0 !important;
  }
  .font-normal {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.16px;
  }
  .bold {
    font-weight: bold;
  }
  .link {
    color: var(--teal-blue);
    text-decoration: none;

    svg {
      g {
        fill: var(--teal-blue);
      }
    }
  }
  .font-tooltip {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #3c4148;
  }
  .font-description {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
  }
  .font-4 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
  }
}
</style>
